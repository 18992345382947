@font-face {
font-family: '__leagueSpartan_4bec7c';
src: url(/_next/static/media/d102c4bc9cb3e846-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__leagueSpartan_Fallback_4bec7c';src: local("Arial");ascent-override: 80.31%;descent-override: 25.24%;line-gap-override: 0.00%;size-adjust: 87.16%
}.__className_4bec7c {font-family: '__leagueSpartan_4bec7c', '__leagueSpartan_Fallback_4bec7c'
}.__variable_4bec7c {--font-league-spartan: '__leagueSpartan_4bec7c', '__leagueSpartan_Fallback_4bec7c'
}

@font-face {
font-family: '__msMadi_9b0c9c';
src: url(/_next/static/media/193cb5abd0c7cd73-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__msMadi_Fallback_9b0c9c';src: local("Arial");ascent-override: 131.27%;descent-override: 61.26%;line-gap-override: 0.00%;size-adjust: 68.56%
}.__className_9b0c9c {font-family: '__msMadi_9b0c9c', '__msMadi_Fallback_9b0c9c'
}.__variable_9b0c9c {--font-ms-madi: '__msMadi_9b0c9c', '__msMadi_Fallback_9b0c9c'
}

